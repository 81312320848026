import React from 'react';
import {observer} from "mobx-react-lite";

const SMC = () => {
  return (
    <section className="courses">
      <div className="container">
        <h3 className="dark_header_about">О семинаре <span>«Алхимия Тени»</span></h3>
        <p className="dark_text_about">«Алхимия Тени» – это неповторимое событие в жизни. Потрясающее по глубине осознаний, красоте совершенства и масштабу возможности изменения того, что принято называть судьбой.</p>
        <p className="dark_text_about dark-tex-alt">«Алхимия Тени» – это трансформационный тренинг, на котором можно увидеть, как и из чего складывается ваша судьба. Из каких фрагментов, аспектов, записей. Как формируется характер и реакции от момента Рождения и до сегодняшнего дня. Вы сможете трансформировать свои шаблоны и привычные, повторяющиеся модели поведения, сделать прорыв и выйти на новый уровень.</p>
        


        <div className="tutor_cursive"><p>«Тень» – хранилище силы<br /> и душевного потенциала</p></div>
        <div className="tutor_vector">
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="48" viewBox="0 0 37 48" fill="none">
            <path d="M36.6247 41.1753C37.0188 40.7884 37.0246 40.1553 36.6377 39.7611L30.3326 33.3389C29.9457 32.9447 29.3125 32.9389 28.9185 33.3259C28.5244 33.7128 28.5185 34.346 28.9054 34.74L34.51 40.4487L28.8013 46.0533C28.4072 46.4402 28.4014 47.0733 28.7882 47.4674C29.1752 47.8616 29.8083 47.8674 30.2024 47.4804L36.6247 41.1753ZM0.773696 0.337563C0.79889 7.10519 2.56353 17.2675 7.81498 25.8047C13.0978 34.3928 21.9185 41.3328 35.9149 41.4617L35.9334 39.4618C22.7279 39.3403 14.4982 32.8522 9.51853 24.7569C4.50755 16.6106 2.79789 6.83413 2.77371 0.330105L0.773696 0.337563Z" fill="#B5B5B5"/>
          </svg>
        </div>
        <div className='box-description'>
          <p><span>Сначала мы это пронаблюдаем на уровне энергий, потом посмотрим, как это происходит в обычной жизни, и как это можно изменить.</span> Высвободится огромный ресурс, энергия, которую тратим на то, чтобы кем-то не быть. Цель семинара - забрать силу у тени и перестать раскидывать бессознательно ее фрагменты в других. «Тень» – хранилище силы и душевного потенциала, а не воплощение всего злого в человеке.</p>
        </div>
        <h2 className="dark_header">Участники могут придти <span> с любым запросом, </span> касается ли это:</h2>
        <div className="courses-block">
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Состояния физического тела</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Ситуации, которой давно не находится решения</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Событий, который часто повторяются в жизни</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Отношений в семье или в коллективе</div>
              </div>
            </div>
          </div>

        </div>    
      </div>
    </section>
    );
};

export default observer(SMC);