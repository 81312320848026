import React from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';

const SLC = () => {
    return (
      <section className="feedback">
        <div className="container">
          <h2 className="dark_header limit-header">Варианты <span> участия и стоимость</span></h2>
          <h2 className="dark_header limit-header header-cost">27 000 руб.</h2>
          <div className="group_circle-btn btn-cost">
            <a href="#mainpage">
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a href="#programs">
              <button className="circle-btn_dashed">
                Орг. информация
              </button>
            </a>
          </div>
        </div>
      </section>
    );
};

export default observer(SLC);