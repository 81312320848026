import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logotype.png';
import { Link } from 'react-router-dom';
import Hero from '../assets/reviews.png';
import Selected from '../assets/list_selected.png';
import List from '../assets/list.png';
import axios from 'axios';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';

const SLF = () => {
  const [checked, setChecked] = useState(1);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');      
  const [model, setModel] = useState(0);
  const [typePayment, setTypePayment] = useState(1);
  const fullPayment = 5500000;
  const fullPaymentDecorative = '55 000';
  const fullPaymentDeposit = 4500000;
  const fullPaymentDepositDecorative = '45 000';
  const prePayment = 2500000;
  const prePaymentDecorative = '25 000';
  const prePaymentDeposit = 1500000;
  const prePaymentDepositDecorative = '15 000';
  const depositDecorative = '10 000';
  const deposit = 1000000;
  const courseName = '«Алхимия тени»';

  function changeChecked(x) {
    setChecked(x);
  } 

  const [practics, setPractics] = useState(true);
  const [buyModal, setBuyModal] = useState(true);
  function ShowModalBuy() {
    setBuyModal(!buyModal);
    if(buyModal) document.getElementById("modalPurchase").style.display = "block";
    else document.getElementById("modalPurchase").style.display = "none";
  }
  const currentYear = new Date().getFullYear();

  function showpractics() {
    setPractics(!practics);
    if(practics) document.getElementById("footerdrop").style.display = "flex";
    else document.getElementById("footerdrop").style.display = "none";
  }

  function initPayment() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    if(model === 0) {
      return errorNotify('Выберите тип обучения!');
    }
    let itemAmount;
    let itemName;
    let purchaseOption;
    if(typePayment === 1) if (model === '1') {
      itemAmount = Number(fullPayment);
      itemName = courseName + ' (Индивидуальный процесс)';
      purchaseOption = 2;
    }
    if(typePayment === 1) if (model === '2') {
      itemAmount = Number(prePayment);
      itemName = courseName + '(Аквариум)';
      purchaseOption = 2;
    }
    if(typePayment === 2) if (model === '1') {
      itemName = courseName + ' (Индивидуальный процесс) - депозит';
      itemAmount = Number(deposit);
      purchaseOption = 1;
    }
    if(typePayment === 2) if (model === '2') {
      itemName = courseName + '(Аквариум) - депозит';
      itemAmount = Number(deposit);
      purchaseOption = 1;
    }
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }

    return (
        <section className="desc-landing">
          <div id="modalPurchase" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuy()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Алхимия тени»</h3>
              <hr className="modal-feedback-hr" />
              <p className="modal-title">Формат участия</p>
              <div className="select-wrapper_buy">
                <select defaultValue={model} onChange={(e) => setModel(e.target.value)} className="form-select_buy">
                  <option value={0}>Выберите формат участия </option>
                  <option value={1}>Индивидуальный процесс </option>
                  <option value={2}>Аквариум </option>
                </select>
              </div>
              <p className="modal-title">Модель оплаты</p>
              { typePayment === 1 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment">Внести депозит</button>
              </div>
              : typePayment === 2 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment_alt">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment_alt">Внести депозит</button>
              </div>
              : null
              }
              { typePayment === 2 ?
                model === 0 ?
                <div className='disclamer-payment-box'>
                  <p>Невозможно рассчитать стоимость курса, необходимо выбрать модель обучения</p>
                </div>
                :
                <div className='disclamer-payment-box'>
                  <p>Вы вносите депозит в размере 10 000 руб; остаток – {model === '1' ? fullPaymentDepositDecorative : model === '2' ? prePaymentDepositDecorative : null}. Оставшуюся сумму необходимо внести за 2 дня до начала курса</p>
                </div>
                : null
              }
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Алхимия тени»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Формат:</p>
                  <p className='card_buy-confirm-text'>{model === '0' ? 'Не выбран формат' : model === '1' ? 'Индивидуальный процесс' : model === '2' ? 'Аквариум' : 'Не выбран формат'}</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>01.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>05.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{typePayment === 1 && model === '1' ? fullPaymentDecorative + ' ₽' : typePayment === 1 && model === '2' ? prePaymentDecorative + ' ₽' : typePayment === 2 ? depositDecorative + ' ₽' : 'Неизвестно'}</p>
                </div>
              </div>
              <button onClick={() => initPayment()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
          <div className='desc-background'>
          </div>
          <div>
          <div className="desc-cards">
            <div className="desc-card">
              <h4 className="desc-card-header-alter"><span>Травмирующие послания из детства ...</span> Сколько их? Как глубоко, далеко мы их запрятали..?</h4>
              <div className='group-items-alt'>
                <div className='group-items-alt_i'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.332 11.75H14.4987" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.4167 19.5013C16.7889 19.5013 20.3333 15.9569 20.3333 11.5846C20.3333 7.21238 16.7889 3.66797 12.4167 3.66797C8.04441 3.66797 4.5 7.21238 4.5 11.5846C4.5 15.9569 8.04441 19.5013 12.4167 19.5013Z" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <p className='group-items-alt-i_text'><span>Ты не достаточно хороша.</span> Всегда можно сделать лучше! Просто нужно ещё постараться!</p>
                </div>
                <div className='group-items-alt_i'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.332 11.75H14.4987" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.4167 19.5013C16.7889 19.5013 20.3333 15.9569 20.3333 11.5846C20.3333 7.21238 16.7889 3.66797 12.4167 3.66797C8.04441 3.66797 4.5 7.21238 4.5 11.5846C4.5 15.9569 8.04441 19.5013 12.4167 19.5013Z" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <p className='group-items-alt-i_text-2'><span>Ты ничего не сможешь без меня!</span> Да если бы ни я, ты вообще не понятно кем стала!</p>
                </div>
                <div className='group-items-alt_i'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.332 11.75H14.4987" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.4167 19.5013C16.7889 19.5013 20.3333 15.9569 20.3333 11.5846C20.3333 7.21238 16.7889 3.66797 12.4167 3.66797C8.04441 3.66797 4.5 7.21238 4.5 11.5846C4.5 15.9569 8.04441 19.5013 12.4167 19.5013Z" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <p className='group-items-alt-i_text-3'><span>У тебя не получится,</span> ты же глупый. Голову включи! Ты что, её где-то забыл?</p>
                </div>
                <div className='group-items-alt_i'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.332 11.75H14.4987" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.4167 19.5013C16.7889 19.5013 20.3333 15.9569 20.3333 11.5846C20.3333 7.21238 16.7889 3.66797 12.4167 3.66797C8.04441 3.66797 4.5 7.21238 4.5 11.5846C4.5 15.9569 8.04441 19.5013 12.4167 19.5013Z" stroke="#FF3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <p className='group-items-alt-i_text-4'><span>Ты не тех выбираешь.</span> С ними не нужно дружить. Он тебе не пара, такой же неудачник, как и...</p>
                </div>
              </div>
              <p className='group-items_description-text'>А вот же они – в заплечном мешке. Он всегда с нами. Из каких аспектов, фрагментов, посланий складывается судьба человека?</p>
              <p className='group-items_description-text'>Как формируется характер от зачатия до настоящего момента?</p>
              <p className='group-items_description-text'>Как мы попадаем в одни те же ситуации множество раз?</p>
            </div>
          </div>
        </div>
        <h2 className='course-description-header'><span>Вы увидите единство:</span> как все, что мы имеем на данный момент закладывается в детстве и как это трансформируется.</h2>
        <p className="course-description-text">Тень – это то, чем вы решили никогда не быть. Это то, что вы прекратили делать, потому что когда-то в Тень могли отправить вашу спонтанность и искренность, вашу способность любить и говорить, быть такой какой нравится.</p>
        <h2 className='header-1'>
          Какая часть вас отрезана и убрана <span>в заплечный мешок?</span>
        </h2>
        <p className='text-1'>
          И почему с годами вам все труднее танцевать танец жизни с этим мешком за плечами? В виртуальный «заплечный мешок» мы могли отправить все «неправильное», «стыдное», спонтанное, то что могло создать вашу уникальную личность. Вместо свободного творчества создания человека, люди получают Судьбу.
        </p>
        <h2 className='header-2'>
          На семинаре «Алхимия Тени» нам <span>откроется как это создано и как это можно изменить</span>
        </h2>
        <p className='text-2'>
          В уникальном безопасном пространстве, мастерски созданном в первый день тренинга вас ожидает большое Путешествие в вашу Теневую часть.
        </p>
        <p className='text-2'>
          <span>Вас будет сопровождать опытный проводник, но вы должны знать, что только ВАШЕ решение</span> двигаться вперёд, сквозь страхи и сомнения приведёт вас на встречу с Силой, которую вы вернёте себе!
        </p>

        <div className="alt-bg">
          <h4 className="desc-card-header"><span>Вашими помощниками</span> будут:</h4>
          <div className='group-items'>
            <div className='group-items-i'>
              <div className='group-items-i_figure i_air'></div>
              <p className='group-items-i_text'>Маг - стихия Воздуха</p>
            </div>
            <div className='group-items-i'>
              <div className='group-items-i_figure i_earth'></div>
              <p className='group-items-i_text'>Монарх - стихия Земли</p>
            </div>
            <div className='group-items-i'>
              <div className='group-items-i_figure i_wather'></div>
              <p className='group-items-i_text'>Любящий - стихия Воды</p>
            </div>
            <div className='group-items-i'>
              <div className='group-items-i_figure i_fire'></div>
              <p className='group-items-i_text'>Воин - стихия Огня</p>
            </div>
          </div>
          <p className="desc-card-text">Через изучение этих архетипов (энергий) вы сможете проанализировать, прочувствовать, осознать как это проявляется в вашей жизни.</p>
          <p className="desc-card-text">Где вы активны, смелы, напористы как Воины, интуитивны как Маг, гибки, веселы, коммуникабельны как Любящий и уверены в себе, как Монарх.</p>
          <p className="desc-card-text"><span>В пространстве семинара «Алхимия Тени» вы глубоко изучите, сбалансируете 4 энергии,</span> получите навыки управления ими и соединитесь утраченными частями, вернёте свою Силу целостность и величие!</p>
        </div>


        <section className="reviews">
          <div className="container">
            <h2 className="dark_header-2">Через мои программы прошли <span>уже более 1000 человек</span> и вот что они говорят:</h2>
            <div className="reviews_block">
              <div className="reviews_img">
                <img className="reviews_hero" src={Hero} />
              </div>
              <div className="reviews_text">
                <p>Хочу поделиться результатом. Каждый раз, когда делаю сессию себе или после сессии с другим, перехожу на лицо и разглаживаю морщинки, как Елена показывала с камнем, представляю, что это энергия, леплю лицо. И невероятно! Как будто сделала подтяжку! Носогубенные морщинки раскрываются. Чудо! Благодарю!</p>
                <p className="reviews_name">Анастасия Семенова</p>
              </div>
            </div>
            <div className="reviews_list">
              <img className="about_list_i" src={checked === 1 ? Selected : List} onClick={() => changeChecked(1)} />
              <img className="about_list_i" src={checked === 2 ? Selected : List} onClick={() => changeChecked(2)} />
              <img className="about_list_i" src={checked === 3 ? Selected : List} onClick={() => changeChecked(3)} />
              <img className="about_list_i" src={checked === 4 ? Selected : List} onClick={() => changeChecked(4)} />
              <img className="about_list_i" src={checked === 5 ? Selected : List} onClick={() => changeChecked(5)} />
            </div>
          </div>
        </section>

        <div className="container">
          <h2 className="dark_header limit-header">Варианты <span> участия и стоимость</span></h2>
          <div className='costs-variants'>
            <div className='cost_variants-items'>
              <h2 className="dark_header limit-header header-cost"><span>55 000</span> руб.</h2>
              <p className='text-landing'>Индивидуальный процесс</p>
            </div>
            <div className='cost_variants-items'>
              <h2 className="dark_header limit-header header-cost"><span>25 000</span> руб.</h2>
              <p className='text-landing'>Аквариум</p>
            </div>
          </div>
          <div className="group_circle-btn btn-cost">
            <a onClick={() => ShowModalBuy()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a href="#programs">
              <button className="circle-btn_dashed">
                Орг. информация
              </button>
            </a>
          </div>
        </div>

        <footer>
        <div className="container">
          <div className="footer_block">
            <div className="footer_block-brand">
              <img className="footer_block-logotype" src={Logo} />
              <p>© {currentYear}, Елена Эра. Все права защищены</p>
            </div>
            <div className="sm-footer-info">
              <a>Расписание</a>
              <a onClick={() => showpractics()}>
                Практики
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </a>
              <div id="footerdrop">
                <Link className="footerdrop-item" to="/shining-hands">- Практики метода «Сияющие руки»</Link>
                <Link className="footerdrop-item" to="/">- Практики метода «Соединение миров»</Link>
              </div>
              <a>Мастера</a>
              <a>Книги</a>
              <a>Блог</a>
            </div>
            <div className="footer_block-info">
              <div className="footer_block-links">
                <Link to="/schedule">Расписание</Link>
                <div className="dropup">
                  <button className="dropupbtn">
                    Практики 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <div className="dropup-content">
                    <a href="#">Практики метода «Сияющие руки»</a>
                    <a href="#">Практики метода «Соединение миров» </a>
                  </div>
                </div>
                <a href="#3">Мастера</a>
                <a href="#3">Книги</a>
                <a href="#3">Блог</a>
              </div>
              <a className="footer_block-policy" href="#3">Политика конфиденциальонсти</a>
            </div>
          </div>
        </div>
      </footer>
      
      </section>
    );
};

export default observer(SLF);